import * as Modal from "../../../components/Modal";

export function QOTWModal() {

  return (
    <Modal.Root
      open={true}
      onOpenChange={() => console.log("on open content here")
      }
    >
      <Modal.SideModal title={"QOTW"}>
        modal content here
      </Modal.SideModal>
    </Modal.Root>
  );
}
